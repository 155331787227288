import { PersonType } from "../../@types/PersonType";

export const peopleData: PersonType[] = [
  {
    name: "Anna Myśliwiec",
    position: "Analytics Lead",
    team: "Consumer Health IT Team",
    color: "red",
    link: "#",
    description: [
      "I am a bridge between business and technology. My role is to hear the needs of the business in the area of analytics, then translate them, convert them into stories, and guide technical teams, so that they deliver an analytical tool that helps the business make the right decisions faster and with higher confidence.",
      "To me, a #techwizard is a fusion of technical genius and independent thinker, willing to learn, and openly share his ideas.",
      "Remember: You do not have to be a programmer to work in IT. An analytical mind, curiosity, the ability to understand technological concepts, and great communication skills are also needed in IT.",
    ],
  },
  {
    name: "Anna Myśliwiec",
    position: "Analytics Lead",
    team: "Consumer Health IT Team",
    color: "red",
    link: "#",
    description: [
      "I am a bridge between business and technology. My role is to hear the needs of the business in the area of analytics, then translate them, convert them into stories, and guide technical teams, so that they deliver an analytical tool that helps the business make the right decisions faster and with higher confidence.",
      "To me, a #techwizard is a fusion of technical genius and independent thinker, willing to learn, and openly share his ideas.",
      "Remember: You do not have to be a programmer to work in IT. An analytical mind, curiosity, the ability to understand technological concepts, and great communication skills are also needed in IT.",
    ],
  },
  {
    name: "Vova Azarko",
    position: "Associate Software Engineer",
    team: "Crop Science IT Team",
    color: "lime",
    link: "#",
    description: [
      "One of the most interesting aspects of my latest project is the opportunity to work with a variety of different systems and applications. I’m working closely with upstream systems to gather and manage user data, as well as integrating our system with various other applications to provide a seamless experience for users.",
      "To me, #techwizards are highly specialized professionals who are experts in computer programming, software development, and data analysis.",
      "One of the things I love about my job is that there are plenty of opportunities for self-development. DHub at Bayer encourages and supports ongoing learning and growth, which helps me stay at the top of my game and continue to deliver high-quality solutions for our clients.",
      "Are you Software Engineer or Data Scientist?",
    ],
  },
  {
    name: "Aleksandra Charążka",
    position: "Enterprise Product Specialist",
    team: "Crop Science IT Team",
    color: "navy",
    link: "#",
    description: [
      "I’m involved in testing the app which we’re building. I’m a part of a project called JARVIS. This tool will help Supply Managers make decisions based on data gathered from all systems they are using (there are even 5 of them). It will help them to see everything in one place and find all the data they need.",
      "A #techwizard is a person with a very high level of technical knowledge. They are experienced and they know all IT secrets, like Senior Engineers, but also Agile Coaches because they were cooperating with many teams in different projects.",
      "At DHub Warsaw I can grow – I learn many new things in my daily work, but there’s also a possibility to attend training or conferences, which is very helpful. Are you a Senior Engineer?",
    ],
  },
  {
    name: "Tomasz Król",
    position: "Lead Data Engineer",
    team: "Data Enablement Team",
    color: "green",
    link: "#",
    description: [
      "I am a coordinator for the Data Services Team in Poland and part of the Data Enablement Team within the Global Data Asset organization. One of my main responsibility is to work on the Datahub platform that enables multiple teams within Crop Science to process stream data via Kafka clusters between different frameworks.",
      "A #techwizard is a technology-oriented person who can resolve any complex problem. Their solutions are simple, unique, and effective. They can easily switch between different technology and explain complex tech problems in simple terms to a small child.",
      "We live in a world where technology changes so fast, and “decades go in weeks” so we must be willing to learn continuously to be up to date. Do you have a curiosity and willingness to solve problems?",
    ],
  },
  {
    name: "Anna Szczurek",
    position: "Senior Machine Learning Ops Engineer",
    team: "Digital Transformation & IT Pharmaceuticals",
    color: "blue",
    link: "#",
    description: [
      "The most interesting project of my team is the technological solutions for Cell & Gene Therapy to build a DataStore in the cloud, which is the foundation for future data-driven Cloud / ML / AI activities. Those activities will unlock the potential of Cell & Gene Therapy data, such as data from bioreactors or samples, to conduct the right experiments, tune parameters in the lab, and bring products faster to market.",
      "Are you Cloud Solution Architect or Cloud Engineer?",
    ],
  },
  {
    name: "Adil Shaikh",
    position: "Junior Software Engineer",
    team: "Crop Science IT Team",
    color: "purple",
    link: "#",
    description: [
      "I get to be in the whole life cycle of the software. I am a part of two projects which are used by farming researchers and scientists. They find our software useful whether they are growing more seeds in Puerto Rico, formulating drought-resisting cotton in South Africa or they are collecting samples in the field or greenhouses for analysis.",
      "For me, #techwizards are my teammates, my seniors. Every time I have a problem, I go to them and return with solutions.",
      "Whom would I encourage to take part in our recruitment? Those who are futuristic and would like to be a part of something big, who would like to learn as well as expand their skill set in a stress-free environment.",
    ],
  },
];
