import React from "react";
import HoverIcon from "./HoverIcon";

const ArrowRight = () => {
  return (
    <HoverIcon>
      <svg
        width="33"
        height="16"
        viewBox="0 0 33 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M32.5699 8.70712C32.9604 8.3166 32.9604 7.68343 32.5699 7.29291L26.2059 0.928943C25.8154 0.538418 25.1823 0.538418 24.7917 0.928942C24.4012 1.31947 24.4012 1.95263 24.7917 2.34316L30.4486 8.00001L24.7917 13.6569C24.4012 14.0474 24.4012 14.6806 24.7917 15.0711C25.1822 15.4616 25.8154 15.4616 26.2059 15.0711L32.5699 8.70712ZM0.862793 9L31.8628 9.00001L31.8628 7.00001L0.862793 7L0.862793 9Z" />
      </svg>
    </HoverIcon>
  );
};

export default ArrowRight;
