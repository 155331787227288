import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { AppContainer, mediaDown } from "../../styles/Mixins";

export const About = styled.section`
  position: relative;
  padding-bottom: 5rem;
  background-color: ${ThemeStyle.colors.white};
  z-index: 3;
`;

export const Content = styled.div`
  ${AppContainer};
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  padding-top: 13rem;
  padding-bottom: 13rem;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      grid-template-columns: 1fr 1.5fr;
      gap: 2rem;
      padding-top: 8rem;
      padding-bottom: 8rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      grid-template-columns: 1fr;
      gap: 4rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 1fr;
      gap: 4rem;
      padding: 4rem 2rem;
    `
  )};
`;

export const Title = styled.h2`
  position: relative;
  font-family: ${ThemeStyle.fonts.second};
  font-weight: ${ThemeStyle.fontWeight.bold};
  font-size: 15rem;
  line-height: 0.8;
  padding: 1rem;
  background: linear-gradient(180deg, #0f8780 0%, #a7c20b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  svg {
    position: absolute;
    top: -3rem;
    left: -6rem;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      font-size: 9rem;
      svg {
        display: none;
      }
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 7rem;
      text-align: center;
      line-height: 0.9;
    `
  )}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      a {
        align-self: center;
      }
    `
  )}
`;

export const Text = styled.p`
  font-size: 2.4rem;
  color: ${ThemeStyle.colors.gray};
  font-weight: ${ThemeStyle.fontWeight.bold};
  margin-bottom: 6rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.8rem;
    `
  )}
`;

export const Quote = styled.p`
  margin-top: 2rem;
  font-weight: ${ThemeStyle.fontWeight.light};
  color: ${ThemeStyle.colors.gray};
  line-height: 1.7;
  margin-bottom: 6rem;
  strong {
    font-weight: ${ThemeStyle.fontWeight.semiBold};
  }
`;

export const Line = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 60%);
`;
