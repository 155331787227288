import React, { FunctionComponent } from "react";

const SvgLine: FunctionComponent = () => {
  return (
    <svg
      width="545"
      height="443"
      viewBox="0 0 545 443"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-355.68 442.271H291.466C445.504 442.271 536.309 341.951 544.399 195.289C551.288 51.1694 451.194 0.335449 368.93 0.335449H165.743"
        stroke="#696969"
        strokeOpacity="0.5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default SvgLine;
