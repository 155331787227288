import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { AppContainer, LinkHoverBefore, mediaDown } from "../../styles/Mixins";

type sectionProps = {
  isVideoPlaying: boolean;
};

export const People = styled.section<sectionProps>`
  position: relative;
  background-color: ${ThemeStyle.colors.black};
  padding-top: 6rem;
  z-index: 3;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 30%;
    aspect-ratio: 1;
    background: ${ThemeStyle.colors.teal};
    filter: blur(20rem);
    box-shadow: 0 0 10rem 10rem ${ThemeStyle.colors.teal};
    z-index: 10;
    transition: opacity 0.2s ease-in 0.2s;
    ${(props: sectionProps) =>
      props.isVideoPlaying &&
      css`
        opacity: 0.3;
      `}
  }
  &::before {
    top: -50%;
    left: -15%;
  }
  &::after {
    top: -30%;
    right: -20%;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      &::before {
        top: -4%;
        left: -5%;
        filter: blur(14rem);
      }
      &::after {
        content: none;
      }
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      padding-top: 0;
      &::before {
        top: -21%;
        filter: blur(10rem);
        box-shadow: 0 0 10rem 3rem ${ThemeStyle.colors.teal};
      }
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      &::before {
        top: -14%;
        left: -20%;
        filter: blur(6rem);
      }
    `
  )};
`;

export const Container = styled.div`
  ${AppContainer};
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding-left: 0 !important;
  z-index: 1;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      padding-left: 0;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      flex-flow: column;
      padding: 0;
    `
  )};
`;

export const TitleWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5rem 0;
  z-index: 6;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 2rem 0;
      overflow: hidden;
    `
  )};
`;

export const TitleMask = styled.div<sectionProps>`
  width: 200%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: ${ThemeStyle.colors.black};
  filter: blur(2rem);
  z-index: 1;
  ${(props: sectionProps) =>
    props.isVideoPlaying &&
    css`
      opacity: 0.9;
    `}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      height: 80%;
      top: 35%;
    `
  )};
`;

export const Title = styled.h2`
  position: relative;
  font-family: ${ThemeStyle.fonts.second};
  font-size: 9rem;
  text-align: center;
  line-height: 1;
  font-weight: ${ThemeStyle.fontWeight.bold};
  color: ${ThemeStyle.colors.white};
  z-index: 6;
  span {
    color: ${ThemeStyle.colors.blue};
  }
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 6rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 5rem;
    `
  )}
`;

export const WrapperLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 3;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      width: 100%;
      height: 50vh;
    `
  )};
`;

export const WrapperRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${ThemeStyle.colors.white};
  .swiper-slide {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 5rem 0 5rem 8rem;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      .swiper-slide {
        padding: 5rem 0 2rem 5rem;
      }
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      .swiper-slide {
        padding: 3rem 0 2rem 4rem;
      }
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      width: 100%;
      flex-direction: column-reverse;
      .swiper-slide {
        padding: 2rem 2rem 2rem 2rem;
      }
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      .swiper-slide {
        padding: 1rem 2rem 2rem 2rem;
      }
    `
  )};
`;

const NameBase = css`
  font-family: ${ThemeStyle.fonts.second};
  font-weight: ${ThemeStyle.fontWeight.bold};
  font-size: 5.2rem;
  line-height: 1;
  padding: 0.5rem 0;
  color: ${ThemeStyle.colors.white};
`;

export const Name = styled.h3`
  ${NameBase};
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      font-size: 4.8rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 4rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 3rem;
    `
  )};
`;

const PositionBase = css`
  font-size: 2rem;
  color: ${ThemeStyle.colors.white};
  span {
    font-weight: ${ThemeStyle.fontWeight.extraLight};
  }
`;

export const Position = styled.h4`
  ${PositionBase};
  transform: translateY(-1rem);
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      font-size: 1.8rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.6rem;
      transform: translateY(0);
      margin-bottom: 1rem;
    `
  )};
`;

export const TextWrapper = styled.div`
  margin: 4rem 0;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      margin: 3rem 0;
      svg {
        height: 28px;
      }
    `
  )};
`;

export const Text = styled.p`
  color: ${ThemeStyle.colors.white};
  margin-top: 2rem;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      margin-top: 1.5rem;
      &:first-of-type {
        margin-top: 0.5rem;
      }
    `
  )};
`;

type Props = {
  hideVideo: boolean;
};

export const PlayVideoButton = styled.button`
  color: ${ThemeStyle.colors.white};
  position: absolute;
  bottom: 2rem;
  left: 50%;
  font-size: 2.4rem;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  white-space: nowrap;
  svg {
    margin-left: 1rem;
    width: 3.5rem;
    height: 3.5rem;
  }
  &::before {
    ${LinkHoverBefore};
    bottom: -4px;
  }
  &:hover {
    &::before {
      width: 60%;
    }
  }
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 2.2rem;
      svg {
        width: 3rem;
        height: 3rem;
      }
    `
  )};
`;

export const LightWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
`;

export const ImageMask = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      object-fit: contain;
      transform: scale(1.8) translateY(-5%);
    `
  )};
`;

export const PersonOld = styled.div<{ hide: boolean }>`
  ${(props) =>
    props.hide &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      ${(props) =>
        props.hide &&
        css`
          display: none;
        `}
    `
  )};
`;

export const Persons = styled.div<{ hide: boolean }>`
  display: none;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      a {
        width: 100%;
      }
      ${(props) =>
        props.hide &&
        css`
          display: none;
        `}
    `
  )};
`;

export const LightImg = styled.img`
  width: 100%;
  height: 100%;
  object-position: top;
`;

export const LightText = styled.div<{ hide: boolean }>`
  position: absolute;
  bottom: 6rem;
  right: 7rem;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      bottom: 1rem;
      right: 1rem;
    `
  )};
`;

export const LightName = styled.p`
  ${NameBase};
  line-height: 0.7;
  font-size: 8rem;
  text-align: right;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 4rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.6rem;
    `
  )};
`;

export const LightPosition = styled.p`
  ${PositionBase};
  text-align: right;
  margin-bottom: 1rem;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      display: flex;
      flex-flow: column;
      font-size: 1.6rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.3rem;
    `
  )};
`;

export const PlayerButtonWrapper = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 1.8rem;
    color: ${ThemeStyle.colors.white};
    margin-top: 2rem;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      p {
        margin-top: 1rem;
      }
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      p {
        margin-top: 0.5rem;
        font-size: 1.4rem;
      }
    `
  )};
`;

export const Video = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: ${ThemeStyle.colors.black};
  transition: ${ThemeStyle.transition};
  transition-property: opacity, visibility;
  margin: auto;
  ${(props: Props) =>
    props.hideVideo &&
    css`
      opacity: 0;
      visibility: hidden;
    `}

  .react-player__preview {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .play-svg {
      transition: ${ThemeStyle.transition};
      transition-property: transform;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      height: 50vh;
      .react-player__preview {
        .play-svg {
          width: 10rem;
          height: 10rem;
        }
      }
      .video-nav {
        display: none;
      }
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      .react-player__preview {
        .play-svg {
          width: 5rem;
          height: 5rem;
        }
      }
    `
  )};
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 8rem 2rem 8rem;
  margin-top: 2rem;
  &.video-nav {
    position: absolute;
    z-index: 5;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      padding: 0 5rem 2rem 5rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      margin-top: 3rem;
      padding: 0 0 2rem 0;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      margin-top: 0;
      padding: 0;
    `
  )}
`;

export const ButtonPrev = styled.button`
  padding: 1rem 2rem 1rem 0;
  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;
export const ButtonNext = styled.button`
  padding: 1rem 1rem 1rem 2rem;
  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;
