import React, { FunctionComponent } from "react";

type Props = {};

const AddIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.08301 15H7.08301V0H8.08301V15Z" fill="white" />
      <path
        d="M15 6.91602L15 7.91602L-9.53674e-07 7.91602L-9.97386e-07 6.91602L15 6.91602Z"
        fill="white"
      />
    </svg>
  );
};

export default AddIcon;
