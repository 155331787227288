import React from "react";
import HoverIcon from "./HoverIcon";

const ArrowLeft = () => {
  return (
    <HoverIcon>
      <svg
        width="33"
        height="16"
        viewBox="0 0 33 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.15569 7.29289C0.765163 7.68341 0.765163 8.31657 1.15569 8.7071L7.51965 15.0711C7.91017 15.4616 8.54334 15.4616 8.93386 15.0711C9.32438 14.6805 9.32438 14.0474 8.93386 13.6568L3.27701 7.99999L8.93386 2.34314C9.32439 1.95262 9.32439 1.31945 8.93386 0.928926C8.54334 0.538402 7.91017 0.538401 7.51965 0.928926L1.15569 7.29289ZM32.8628 7L1.86279 6.99999L1.86279 8.99999L32.8628 9L32.8628 7Z" />
      </svg>
    </HoverIcon>
  );
};

export default ArrowLeft;
