import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { mediaDown } from "../../../styles/Mixins";

const HoverIcon = styled.svg`
  transition: 0.3s;
  fill: ${ThemeStyle.colors.white};
  width: 33px;
  height: 16px;

  &:hover {
    fill: ${ThemeStyle.colors.gray};
  }
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      &:hover {
        fill: ${ThemeStyle.colors.white};
      }
    `
  )};
`;
export default HoverIcon;
