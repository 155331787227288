import React, { FunctionComponent } from "react";

const Science: FunctionComponent = () => {
  return (
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_238_101)">
        <path
          d="M36.1104 36.425C36.0004 36.425 35.8903 36.425 35.7803 36.415C34.7503 36.325 33.7704 35.855 32.9904 35.085L30.7704 32.865C30.2804 32.375 30.2804 31.585 30.7704 31.095C31.2604 30.605 32.0504 30.605 32.5404 31.095L34.7603 33.315C35.1103 33.665 35.5604 33.885 35.9904 33.915C36.2004 33.935 36.5904 33.915 36.8703 33.635C37.3404 33.165 37.1804 32.205 36.5204 31.545L24.5404 19.565C24.0504 19.075 24.0504 18.285 24.5404 17.795C25.0304 17.305 25.8204 17.305 26.3104 17.795L38.2904 29.775C39.9404 31.425 40.1004 33.945 38.6404 35.405C37.9904 36.065 37.0804 36.425 36.1104 36.425Z"
          fill="#00B2E6"
        />
        <path
          d="M30.68 38.065C29.59 38.065 28.44 37.615 27.56 36.735L23.16 32.335C22.67 31.845 22.67 31.055 23.16 30.565C23.65 30.075 24.44 30.075 24.93 30.565L29.33 34.965C29.99 35.625 30.96 35.775 31.44 35.295C31.92 34.815 31.77 33.845 31.11 33.185L21.02 23.105C20.53 22.615 20.53 21.825 21.02 21.335C21.51 20.845 22.3 20.845 22.79 21.335L32.87 31.415C34.52 33.055 34.67 35.585 33.21 37.055C32.53 37.735 31.63 38.065 30.69 38.065H30.68Z"
          fill="#00B2E6"
        />
        <path
          d="M23.3404 37.2951C23.2404 37.2951 23.1304 37.2951 23.0304 37.2851C22.0704 37.2051 21.1504 36.7651 20.4304 36.0451C20.4304 36.0451 12.3804 27.9951 12.3404 27.9551C7.59043 23.2051 6.15043 20.0351 4.88043 17.2351C3.85043 14.9551 2.95043 12.9951 0.37043 10.4051C-0.11957 9.91508 -0.11957 9.12508 0.37043 8.63508C0.86043 8.14508 1.65043 8.14508 2.14043 8.63508C5.04043 11.5351 6.07043 13.7951 7.16043 16.1951C8.40043 18.9251 9.68043 21.7551 14.1204 26.1951C14.2604 26.3351 22.2004 34.2751 22.2004 34.2751C22.5004 34.5751 22.8804 34.7551 23.2404 34.7951C23.4104 34.8151 23.7304 34.7951 23.9504 34.5751C24.1704 34.3551 24.1804 34.0351 24.1704 33.8651C24.1404 33.4951 23.9504 33.1151 23.6504 32.8251L17.4704 26.6451C16.9804 26.1551 16.9804 25.3651 17.4704 24.8751C17.9604 24.3851 18.7504 24.3851 19.2404 24.8751L25.4204 31.0551C26.1404 31.7751 26.5804 32.6951 26.6604 33.6551C26.7504 34.6751 26.4104 35.6551 25.7204 36.3451C25.1104 36.9651 24.2504 37.3051 23.3404 37.3051V37.2951Z"
          fill="#00B2E6"
        />
        <path
          d="M36.79 30.045C36.69 30.045 36.58 30.045 36.48 30.035C35.45 29.955 34.45 29.485 33.68 28.715C33.19 28.225 33.19 27.435 33.68 26.945C34.17 26.455 34.96 26.455 35.45 26.945C35.8 27.285 36.24 27.505 36.68 27.545C36.89 27.565 37.29 27.545 37.57 27.265C37.83 27.005 37.86 26.645 37.84 26.405C37.8 25.975 37.58 25.525 37.22 25.175L25.25 13.205C24.82 12.775 24.76 12.105 25.11 11.605C25.45 11.105 26.11 10.925 26.65 11.175C27.86 11.725 28.89 12.175 30.91 13.055L31.12 13.145C34.07 14.435 36.33 14.605 37.25 14.195C37.42 14.125 37.47 14.055 37.47 14.045C37.75 13.265 34.77 11.815 32.98 10.955C32.59 10.765 32.19 10.575 31.8 10.375C30.83 9.89503 30.06 9.45503 29.38 9.06503C27.37 7.91503 25.79 7.01503 20.34 5.73503C19.44 5.52503 18.54 5.42503 17.59 5.32503C14.97 5.04503 12.01 4.72503 9.02003 1.73503C8.53003 1.24503 8.53003 0.455034 9.02003 -0.0349658C9.51003 -0.524966 10.3 -0.524966 10.79 -0.0349658C13.15 2.32503 15.34 2.56503 17.86 2.83503C18.85 2.94503 19.87 3.05503 20.92 3.29503C26.72 4.66503 28.53 5.69503 30.63 6.89503C31.31 7.28503 32.02 7.68503 32.92 8.13503C33.3 8.32503 33.69 8.51503 34.07 8.69503C37.41 10.315 40.86 11.985 39.82 14.875C39.64 15.365 39.22 16.045 38.23 16.475C36.71 17.135 34.3 16.945 31.6 16.005L38.97 23.375C39.74 24.145 40.22 25.125 40.31 26.145C40.41 27.235 40.05 28.275 39.32 29.005C38.66 29.665 37.75 30.025 36.77 30.025L36.79 30.045Z"
          fill="#00B2E6"
        />
        <path
          d="M45.1 37.3049C44.78 37.3049 44.46 37.1849 44.22 36.9349L42.3 35.0149C41.81 34.5249 41.81 33.7349 42.3 33.2449C42.79 32.7549 43.58 32.7549 44.07 33.2449L45.99 35.1649C46.48 35.6549 46.48 36.4449 45.99 36.9349C45.75 37.1749 45.43 37.3049 45.11 37.3049H45.1Z"
          fill="#A7C20B"
        />
        <path
          d="M40.3504 42.045C40.0304 42.045 39.7104 41.925 39.4704 41.675L37.1204 39.325C36.6304 38.835 36.6304 38.045 37.1204 37.555C37.6104 37.065 38.4004 37.065 38.8904 37.555L41.2404 39.905C41.7304 40.395 41.7304 41.185 41.2404 41.675C41.0004 41.915 40.6804 42.045 40.3604 42.045H40.3504Z"
          fill="#A7C20B"
        />
        <path
          d="M35.58 46.8149C35.26 46.8149 34.94 46.6949 34.7 46.4449L30.14 41.8849C29.65 41.3949 29.65 40.6049 30.14 40.1149C30.63 39.6249 31.42 39.6249 31.91 40.1149L36.47 44.6749C36.96 45.1649 36.96 45.9549 36.47 46.4449C36.23 46.6849 35.91 46.8149 35.59 46.8149H35.58Z"
          fill="#A7C20B"
        />
        <path
          d="M57.42 69.815C57.1 69.815 56.78 69.695 56.54 69.445C53.01 65.915 50.33 64.705 47.23 63.295C43.5 61.605 39.27 59.685 32.92 53.335C32.76 53.175 20.58 40.995 20.58 40.995L22.35 39.225C24.4 41.275 34.63 51.505 34.69 51.575C40.71 57.595 44.55 59.335 48.26 61.025C51.44 62.475 54.45 63.835 58.3 67.685C58.79 68.175 58.79 68.965 58.3 69.455C58.06 69.695 57.74 69.825 57.42 69.825V69.815Z"
          fill="#A7C20B"
        />
        <path
          d="M69.0804 58.1549C68.7604 58.1549 68.4404 58.0349 68.2004 57.7849C64.2904 53.8749 63.8904 50.1349 63.5004 46.5149C63.3604 45.2149 63.2304 43.9949 62.9404 42.7549C61.2004 35.3649 59.9704 33.2049 58.4104 30.4749C57.8704 29.5149 57.3004 28.5349 56.6604 27.2349C56.4004 26.7149 56.1404 26.1849 55.8904 25.6549C54.4304 22.6449 52.6004 18.9049 51.2004 19.4049C50.9904 19.4849 50.8404 19.7349 50.7504 19.9449C50.1704 21.2849 50.3904 24.4549 52.1704 28.5349C53.8504 32.3749 54.3804 33.5949 55.5004 36.0249C56.0704 37.2749 56.0404 38.7149 55.4104 39.9849C54.5604 41.6949 53.4604 45.0149 55.6004 47.9949C56.0004 48.5549 55.8704 49.3349 55.3104 49.7449C54.7504 50.1449 53.9704 50.0149 53.5604 49.4549C50.6604 45.4149 52.0604 41.0849 53.1604 38.8749C53.4504 38.2849 53.4704 37.6249 53.2104 37.0749C52.0304 34.5149 51.4404 33.1649 49.8704 29.5449C47.9804 25.2249 47.4504 21.2649 48.4504 18.9649C48.9604 17.7749 49.7704 17.2749 50.3504 17.0649C53.8804 15.8049 56.0404 20.2649 58.1404 24.5749C58.3904 25.0949 58.6404 25.6149 58.9004 26.1349C59.5104 27.3649 60.0604 28.3149 60.5804 29.2449C62.1704 32.0349 63.5504 34.4449 65.3704 42.1849C65.7004 43.5749 65.8404 44.9349 65.9904 46.2449C66.3504 49.5749 66.6904 52.7249 69.9804 56.0149C70.4704 56.5049 70.4704 57.2949 69.9804 57.7849C69.7404 58.0249 69.4204 58.1549 69.1004 58.1549H69.0804Z"
          fill="#A7C20B"
        />
        <path
          d="M53.67 36.335C53.35 36.335 53.03 36.215 52.79 35.965L43.43 26.605C42.94 26.115 42.94 25.325 43.43 24.835C43.92 24.345 44.71 24.345 45.2 24.835L54.56 34.195C55.05 34.685 55.05 35.475 54.56 35.965C54.32 36.205 54 36.335 53.68 36.335H53.67Z"
          fill="#A7C20B"
        />
      </g>
      <defs>
        <clipPath id="clip0_238_101">
          <rect width="70.33" height="70.21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Science;
