import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { ErrorText, LabelText } from "../Form.styled";
import { mediaDown } from "../../../styles/Mixins";

type InputProps = {
  state: "typing" | "error" | "valid" | "empty";
};

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
  align-items: center;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 1fr;
      gap: 1rem;
    `
  )};
`;

export const Label = styled(LabelText)<InputProps>`
  position: relative;
  color: ${ThemeStyle.colors.white};
  font-size: 2rem;
  transform: translateY(0);
  line-height: 1;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.6rem;
    `
  )};
`;

export const ButtonWrapper = styled.div`
  position: relative;
`;

export const HiddenInput = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  input {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
`;

export const Button = styled.button<InputProps>`
  width: 100%;
  position: relative;
  font-size: 1.8rem;
  font-family: ${ThemeStyle.fonts.main};
  color: ${ThemeStyle.colors.white};
  border-bottom: 1px solid ${ThemeStyle.colors.white};
  text-align: left;
  padding-left: 0;
  padding-right: 2.5rem;
  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.6rem;
    `
  )};
  ${(props: InputProps) =>
    props.state === "error" &&
    css`
      border-color: ${ThemeStyle.colors.error};
    `};
`;

export const Error = styled(ErrorText)`
  top: 100%;
`;
