import styled, { css } from "styled-components";
import { ErrorText, LabelText } from "../Form.styled";
import ThemeStyle from "../../../styles/ThemeStyle";
import SelectsArrow from "../../../images/Form/select-arrow.svg";
import { mediaDown } from "../../../styles/Mixins";

export const SelectWrapper = styled.div`
  position: relative;
`;

export const Select = styled.select`
  width: 100%;
  font-size: 2rem;
  font-family: ${ThemeStyle.fonts.main};
  border: none;
  padding-bottom: 5px;
  border-bottom: 1px solid ${ThemeStyle.colors.white};
  color: ${ThemeStyle.colors.white};
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background: url(${SelectsArrow}) no-repeat;
  background-position: right;
  cursor: pointer;
  option {
    color: ${ThemeStyle.colors.black};
  }
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.6rem;
    `
  )};
`;

export const Label = styled(LabelText)`
  transform: translateY(-1rem);
  font-size: 1.1rem;
`;

export const Error = styled(ErrorText)``;
