import React, {
  FunctionComponent,
  useEffect,
  useState,
  ChangeEvent,
} from "react";
import { useFormContext } from "react-hook-form";
import { InputProps } from "../FormProps";
import * as Styled from "./FileInput.styled";
import AddIcon from "./AddIcon";

type Props = InputProps;

const FileInput: FunctionComponent<Props> = (props) => {
  const {
    register,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const [state, setState] = useState<"error" | "valid" | "empty">("empty");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const handleError = (isError: boolean) => {
    if (isError) {
      setError("fileUploaded", {
        type: "filetype",
        message: "Field is required",
      });
      setState("error");
    } else {
      clearErrors("fileUploaded");
      setState("valid");
    }
  };

  useEffect(() => {
    if (isSubmitting) {
      setIsFormSubmitted(true);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (!isFormSubmitted) {
      return;
    }
    if (uploadedFile) {
      handleError(false);
    } else {
      handleError(true);
    }
  }, [isFormSubmitted, uploadedFile]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      setUploadedFile(null);
      return;
    }
    setUploadedFile(e.target.files[0]);
    if (e.target.files[0]) {
      const stringName = e.target.files[0].name;
      const name = stringName.substring(0, stringName.lastIndexOf("."));
      setUploadedFileName(name);
    } else {
      setUploadedFileName(null);
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Label htmlFor={props.name} state={state}>
        {props.label}
      </Styled.Label>
      <Styled.ButtonWrapper>
        <Styled.HiddenInput>
          <input
            id={"file"}
            {...register(props.name)}
            onChange={handleFileChange}
            type="file"
            accept={"image/jpeg, image/jpg, image/png, application/pdf"}
          />
        </Styled.HiddenInput>
        <Styled.Button state={state}>
          {uploadedFile ? `${uploadedFileName}` : "click to select"}
          <AddIcon />
        </Styled.Button>
        {errors.fileUploaded && (
          <Styled.Error>{errors.fileUploaded?.message}</Styled.Error>
        )}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

export default FileInput;
