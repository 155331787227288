import styled, { css } from "styled-components";
import { AppContainer, mediaDown } from "../../styles/Mixins";
import ThemeStyle from "../../styles/ThemeStyle";

export const Section = styled.section`
  position: relative;
  width: 100%;
  background-color: ${ThemeStyle.colors.white};
`;

export const Container = styled.div`
  ${AppContainer};
  padding-top: 8rem;
  padding-bottom: 10rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-bottom: 5rem;
    `
  )}
`;

export const Title = styled.h2`
  font-family: ${ThemeStyle.fonts.second};
  font-size: 9rem;
  line-height: 1;
  font-weight: ${ThemeStyle.fontWeight.bold};
  text-align: left;
  width: 100%;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 6rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 5rem;
      text-align: center;
    `
  )}
`;

export const Text = styled.p`
  font-size: 2rem;
  text-align: left;
  width: 100%;
  margin: 2rem 0 3rem;
  max-width: 74rem;
  align-self: flex-start;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.8rem;
    `
  )}
`;

export const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15rem;
  margin-bottom: 8rem;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      gap: 5rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      grid-template-columns: 1fr;
    `
  )}
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 5rem;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      width: 60%;
      align-items: center;
      margin: auto;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      width: 100%;
      padding-top: 3rem;
      align-items: center;
    `
  )}
`;

export const ItemTitle = styled.h3`
  font-size: 2.8rem;
  margin: 2rem 0;
  font-weight: ${ThemeStyle.fontWeight.medium};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2rem;
      margin: 2rem 0 0.5rem;
    `
  )}
`;

export const ItemDescription = styled.p`
  font-size: 1.8rem;
  display: flex;
  text-align: left;
  color: ${ThemeStyle.colors.gray};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      text-align: center;
      font-size: 1.6rem;
    `
  )}
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 1fr;
      gap: 3rem;
    `
  )}
`;
