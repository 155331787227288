import React, { FunctionComponent } from "react";
import * as Styled from "./Select.styled";
import { InputProps, Option } from "../FormProps";
import useError from "../useError";
import { useFormContext, useWatch } from "react-hook-form";

type Props = InputProps & {
  placeholder?: string;
  options: Option[];
};

const Select: FunctionComponent<Props> = (props) => {
  const { register } = useFormContext();
  const value = useWatch({ name: props.name });
  const error = useError(props.name);
  const [state, setState] = React.useState<
    "typing" | "error" | "valid" | "empty"
  >("empty");

  return (
    <Styled.SelectWrapper>
      <Styled.Label>{props.label}</Styled.Label>
      <Styled.Select
        id={props.name}
        {...register(props.name, { required: props.required })}
      >
        {props.placeholder && (
          <option value={props.placeholder} disabled={true}>
            {props.placeholder}
          </option>
        )}
        {props.options.map((option) => (
          <option
            value={option.value}
            key={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </Styled.Select>
    </Styled.SelectWrapper>
  );
};

export default Select;
