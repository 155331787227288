import React, { FunctionComponent } from "react";

type Props = {
  size: "small" | "large";
};

const PlayButton: FunctionComponent<Props> = ({ size }) => {
  return (
    <>
      {size === "small" && (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={"play-svg"}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5449 16.2102C12.717 16.2102 16.0992 12.828 16.0992 8.65586C16.0992 4.48372 12.717 1.10154 8.5449 1.10154C4.37276 1.10154 0.990576 4.48372 0.990576 8.65586C0.990576 12.828 4.37276 16.2102 8.5449 16.2102ZM8.5449 16.4039C12.824 16.4039 16.2929 12.935 16.2929 8.65586C16.2929 4.37674 12.824 0.907837 8.5449 0.907837C4.26578 0.907837 0.796875 4.37674 0.796875 8.65586C0.796875 12.935 4.26578 16.4039 8.5449 16.4039Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.54538 16.1179C12.6666 16.1179 16.0074 12.777 16.0074 8.65586C16.0074 4.53469 12.6666 1.19382 8.54538 1.19382C4.42421 1.19382 1.08334 4.53469 1.08334 8.65586C1.08334 12.777 4.42421 16.1179 8.54538 16.1179ZM16.3857 8.65586C16.3857 12.9859 12.8755 16.4962 8.54538 16.4962C4.2153 16.4962 0.705078 12.9859 0.705078 8.65586C0.705078 4.32578 4.2153 0.815552 8.54538 0.815552C12.8755 0.815552 16.3857 4.32578 16.3857 8.65586ZM16.0997 8.65586C16.0997 12.828 12.7175 16.2102 8.54538 16.2102C4.37325 16.2102 0.991061 12.828 0.991061 8.65586C0.991061 4.48372 4.37325 1.10153 8.54538 1.10153C12.7175 1.10153 16.0997 4.48372 16.0997 8.65586ZM16.2934 8.65586C16.2934 12.935 12.8245 16.4039 8.54538 16.4039C4.26627 16.4039 0.79736 12.935 0.79736 8.65586C0.79736 4.37674 4.26627 0.907834 8.54538 0.907834C12.8245 0.907834 16.2934 4.37674 16.2934 8.65586Z"
            fill="white"
          />
          <path
            d="M10.0287 8.31344C10.3036 8.45894 10.3036 8.85276 10.0287 8.99825L7.75766 10.2002C7.49965 10.3367 7.18904 10.1497 7.18904 9.85776L7.18904 7.45394C7.18904 7.16203 7.49965 6.97499 7.75766 7.11154L10.0287 8.31344Z"
            fill="white"
          />
        </svg>
      )}
      {size === "large" && (
        <svg
          width="152"
          height="152"
          viewBox="0 0 152 152"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={"play-svg"}
        >
          <path
            d="M147.817 76.087C147.817 115.7 115.705 147.813 76.0916 147.813C36.4785 147.813 4.3657 115.7 4.3657 76.087C4.3657 36.4739 36.4785 4.36112 76.0916 4.36112C115.705 4.36112 147.817 36.4739 147.817 76.087ZM76.0916 151.16C117.553 151.16 151.165 117.549 151.165 76.087C151.165 34.6253 117.553 1.01394 76.0916 1.01394C34.6299 1.01394 1.01851 34.6253 1.01851 76.087C1.01851 117.549 34.6299 151.16 76.0916 151.16Z"
            fill="white"
            stroke="white"
            strokeWidth="1.48897"
          />
          <path
            d="M104.51 69.6564C109.673 72.3889 109.673 79.785 104.51 82.5175L61.8593 105.09C57.0138 107.654 51.1804 104.142 51.1804 98.6593L51.1804 53.5145C51.1804 48.0323 57.0138 44.5196 61.8593 47.084L104.51 69.6564Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};

export default PlayButton;
