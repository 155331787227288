import { Option } from "../../../components/Form/FormProps";

export const PositionsList: Option[] = [
  { value: "unknown", label: "choose position" },
  {
    value: "IT Compliance Consultant",
    label: "IT Compliance Consultant",
  },
  {
    value: "IAM Engineer",
    label: "IAM Engineer",
  },
  {
    value: "Senior Cloud Engineer",
    label: "Senior Cloud Engineer",
  },
  {
    value: "Lead Data Architect Market Analytics",
    label: "Lead Data Architect Market Analytics",
  },
  {
    value: "Sr Data Architect PS Analytics",
    label: "Sr Data Architect PS Analytics",
  },
  {
    value: "IT Security Risk Consultant",
    label: "IT Security Risk Consultant",
  },
  {
    value: "Principal Data Engineer (Go)",
    label: "Principal Data Engineer (Go)",
  },
  {
    value: "Staff Data Steward",
    label: "Staff Data Steward",
  },
  {
    value: "Senior Software Engineer (JS/Typescript/React/AWS)",
    label: "Senior Software Engineer (JS/Typescript/React/AWS)",
  },
  {
    value: "Senior Software Engineer - Full Stack (JavaScript, Node, AWS)",
    label: "Senior Software Engineer - Full Stack (JavaScript, Node, AWS)",
  },
  {
    value: "Senior Software Engineer - Full Stack (Node.JS, React, AWS)",
    label: "Senior Software Engineer - Full Stack (Node.JS, React, AWS)",
  },
  {
    value: "Software Engineer - Full Stack (Node.JS, React, AWS)",
    label: "Software Engineer - Full Stack (Node.JS, React, AWS)",
  },

  { value: "other", label: "other" },
];
