import React, { FunctionComponent } from "react";
import { ColorType } from "../../@types/ColorType";
import ThemeStyle from "../../styles/ThemeStyle";

type Props = {
  color: ColorType;
};

const Line: FunctionComponent<Props> = ({ color }) => {
  // @ts-ignore
  const fillColor = ThemeStyle.colors[color];

  return (
    <svg
      width="89"
      height="16"
      viewBox="0 0 89 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3493 15.0315L19.5854 0.0184937H17.7574L14.5213 15.0315H16.3493Z"
        fill={fillColor}
      />
      <path
        d="M1.94108 15.0315L5.17716 0.0184937H3.34919L0.113098 15.0315H1.94108Z"
        fill={fillColor}
      />
      <path
        d="M6.75944 15.0315L9.99552 0.0184937H8.16755L4.93146 15.0315H6.75944Z"
        fill={fillColor}
      />
      <path
        d="M11.5309 15.0315L14.767 0.0184937H12.939L9.70294 15.0315H11.5309Z"
        fill={fillColor}
      />
      <path
        d="M21.4809 15.0315L24.717 0.0184937H22.8941L19.658 15.0315H21.4809Z"
        fill={fillColor}
      />
      <path
        d="M26.2992 15.0315L29.5353 0.0184937H27.7125L24.4764 15.0315H26.2992Z"
        fill={fillColor}
      />
      <path
        d="M30.9176 15.0315L34.1537 0.0184937H32.3257L29.0897 15.0315H30.9176Z"
        fill={fillColor}
      />
      <path
        d="M36.0485 15.0315L39.2846 0.0184937H37.4566L34.2205 15.0315H36.0485Z"
        fill={fillColor}
      />
      <path
        d="M40.8656 15.0315L44.1017 0.0184937H42.2738L39.0428 15.0315H40.8656Z"
        fill={fillColor}
      />
      <path
        d="M60.3395 15.0315L63.5756 0.0184937H61.7476L58.5115 15.0315H60.3395Z"
        fill={fillColor}
      />
      <path
        d="M45.9293 15.0315L49.1654 0.0184937H47.3375L44.1014 15.0315H45.9293Z"
        fill={fillColor}
      />
      <path
        d="M50.7477 15.0315L53.9838 0.0184937H52.1558L48.9197 15.0315H50.7477Z"
        fill={fillColor}
      />
      <path
        d="M55.5212 15.0315L58.7573 0.0184937H56.9293L53.6932 15.0315H55.5212Z"
        fill={fillColor}
      />
      <path
        d="M65.4704 15.0315L68.7065 0.0184937H66.8785L63.6424 15.0315H65.4704Z"
        fill={fillColor}
      />
      <path
        d="M70.2875 15.0315L73.5236 0.0184937H71.6956L68.4647 15.0315H70.2875Z"
        fill={fillColor}
      />
      <path
        d="M74.9008 15.0315L78.142 0.0184937H76.314L73.0779 15.0315H74.9008Z"
        fill={fillColor}
      />
      <path
        d="M80.0368 15.0315L83.2729 0.0184937H81.4449L78.2088 15.0315H80.0368Z"
        fill={fillColor}
      />
      <path
        d="M84.8551 15.0315L88.0912 0.0184937H86.2633L83.0272 15.0315H84.8551Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Line;
