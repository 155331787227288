import React, { FunctionComponent } from "react";
import { ColorType } from "../../@types/ColorType";
import ThemeStyle from "../../styles/ThemeStyle";

type Props = {
  color: ColorType;
};

const QuotationMark: FunctionComponent<Props> = ({ color }) => {
  // @ts-ignore
  const fillColor = ThemeStyle.colors[color];

  return (
    <svg
      width="44"
      height="36"
      viewBox="0 0 44 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6221 17.6064H11.4231L16.3939 0.46582H8.6806L0.453125 19.4918V35.7754H18.6221V17.6064ZM43.6474 17.6064H36.4483L41.4191 0.46582H33.7058L25.4784 19.4918V35.7754H43.6474V17.6064Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default QuotationMark;
