import * as React from "react";
import { FunctionComponent } from "react";
import MainLayout from "../layouts/MainLayout";
import FormSection from "../sections/FormSection/FormSection";
import PeopleSlider from "../sections/PeopleSlider/PeopleSlider";
import About from "../sections/About/About";
import Values from "../sections/Values/Values";

const IndexPage: FunctionComponent = () => (
  <MainLayout>
    <PeopleSlider />
    <About />
    <Values />
    <FormSection />
  </MainLayout>
);

export default IndexPage;
