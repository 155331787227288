import React from "react";
import * as Styled from "./About.styled";
import ButtonLink from "../../components/Button/ButtonLink";
import SvgLine from "./components/SvgLine";
import QuotationMark from "../../components/QuotationMark/QuotationMark";
import Line from "../../components/Lines/Line";

const About = () => {
  return (
    <Styled.About id={"about"}>
      <Styled.Content>
        <Styled.Title>
          About <br />
          Digital Hub <br />
          Warsaw
          <SvgLine />
        </Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            We develop innovative solutions of global scope for Bayer’s Crop
            Science, Pharmaceuticals, and Consumer Health divisions. We
            collaborate closely with colleagues in Germany, the USA, Brazil, and
            many other countries worldwide to support Bayer’s vision of “Health
            for all, hunger for none”.
          </Styled.Text>
          <QuotationMark color={"lime"} />
          <Styled.Quote>
            We are in a very unique situation where we can connect the brightest
            minds on Earth with data and technology to address the biggest
            challenges in healthcare and nutrition. I&nbsp;truly believe that
            together with you, we can accelerate the digital transformation at
            Bayer and shape the digital future to make people happy.
            <br /> <br />
            <strong>Bogusław Tobiasz</strong>, Head of Digital Hub Warsaw,
            Poland
          </Styled.Quote>
          <ButtonLink text={"Apply now!"} link={"#form"} color={"red"} />
        </Styled.TextWrapper>
      </Styled.Content>
      <Styled.Line>
        <Line color={"turquoise"} />
      </Styled.Line>
    </Styled.About>
  );
};

export default About;
