import React, { useState, useRef, useEffect, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import * as Styled from "./PeopleSlider.styled";
import img0 from "../../images/PeopleSlider/techwizards.png";
import img1 from "../../images/PeopleSlider/a.mysliwiec.jpg";
import img2 from "../../images/PeopleSlider/v.azarko.jpg";
import img3 from "../../images/PeopleSlider/a.charazka.jpg";
import img4 from "../../images/PeopleSlider/t.krol.jpg";
import img5 from "../../images/PeopleSlider/a.szczurek.jpg";
import img6 from "../../images/PeopleSlider/a.shaikh.jpg";

import thumbnail0 from "../../images/PeopleSlider/techwizards-bg.jpg";
import thumbnail1 from "../../images/PeopleSlider/a.mysliwiec-thumbnail.jpg";
import thumbnail2 from "../../images/PeopleSlider/v.azarko-thumbnail.jpg";
import thumbnail3 from "../../images/PeopleSlider/a.charazka-thumbnail.jpg";
import thumbnail4 from "../../images/PeopleSlider/t.krol-thumbnail.jpg";
import thumbnail5 from "../../images/PeopleSlider/a.szczurek-thumbnail.jpg";
import thumbnail6 from "../../images/PeopleSlider/a.shaikh-thumbnail.jpg";

import video0 from "../../images/PeopleSlider/techwizards.mp4";
import video1 from "../../images/PeopleSlider/a.mysliwiec.mp4";
import video2 from "../../images/PeopleSlider/v.azarko.mp4";
import video3 from "../../images/PeopleSlider/a.charazka.mp4";
import video4 from "../../images/PeopleSlider/t.krol.mp4";
import video5 from "../../images/PeopleSlider/a.szczurek.mp4";
import video6 from "../../images/PeopleSlider/a.shaikh.mp4";

import ArrowLeft from "./Arrows/ArrowLeft";
import ArrowRight from "./Arrows/ArrowRight";
import { useLocation } from "@reach/router";
import ReactPlayer from "react-player";
import { peopleData } from "./PeopleData";
import ButtonLink from "../../components/Button/ButtonLink";
import Line from "../../components/Lines/Line";
import QuotationMark from "../../components/QuotationMark/QuotationMark";
import PlayButton from "../../components/PlayButton/PlayButton";
import scrollTo from "gatsby-plugin-smoothscroll";
import { ImageMask } from "./PeopleSlider.styled";

const peopleImgs: string[] = [img0, img1, img2, img3, img4, img5, img6];
const peopleVideos: string[] = [
  video0,
  video1,
  video2,
  video3,
  video4,
  video5,
  video6,
];
const peopleVideoThumbnails: string[] = [
  thumbnail0,
  thumbnail1,
  thumbnail2,
  thumbnail3,
  thumbnail4,
  thumbnail5,
  thumbnail6,
];

const PeopleSlider = () => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  const [currentSlideNumber, setCurrentSlideNumber] = useState<number>(0);
  const [currentSlideImg, setCurrentSlideImg] = useState<string>(peopleImgs[0]);
  const [currentVideoThumbnail, setCurrentVideoThumbnail] = useState<string>(
    peopleVideoThumbnails[0]
  );
  const [currentVideo, setCurrentVideo] = useState<string>(peopleVideos[0]);
  const [hideVideo, setHideVideo] = useState<boolean>(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [hideVideoArrow, setHideVideoArrow] = useState<boolean>(false);
  const videoWrapper = useRef(null);
  const video = useRef(null);
  // @ts-ignore
  const [swiper, setSwiper] = useState<Swiper>();
  const [player, setPlayer] = useState<ReactPlayer>();

  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();

  const videoParam = useMemo(() => {
    if (!location.search) {
      return -1;
    }
    if (location.search.includes("anna-m")) {
      return 1;
    } else if (location.search.includes("vova")) {
      return 2;
    } else if (location.search.includes("aleksandra")) {
      return 3;
    } else if (location.search.includes("tomasz")) {
      return 4;
    } else if (location.search.includes("anna-sz")) {
      return 5;
    } else if (location.search.includes("adil")) {
      return 6;
    } else if (location.search.includes("video")) {
      return 1;
    } else {
      return -1;
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 560);
    }
  }, []);

  useEffect(() => {
    if (!swiper) {
      return;
    }
    if (videoParam > 0) {
      setCurrentVideoThumbnail(peopleVideoThumbnails[videoParam - 1]);
      setCurrentSlideNumber(videoParam - 1);
      swiper.slideTo(videoParam - 1);
      setHideVideo(false);
      if (isMobile) {
        scrollTo("#wizards", "nearest");
      } else {
        scrollTo("#wizards", "center");
      }
    } else if (videoParam < 0) {
      setCurrentVideoThumbnail(peopleVideoThumbnails[0]);
      setCurrentSlideNumber(0);
      swiper.slideTo(0);
      setHideVideo(false);
    } else {
      setCurrentVideoThumbnail(peopleVideoThumbnails[0]);
      setCurrentSlideNumber(0);
      swiper.slideTo(0);
      setHideVideo(false);
      if (isMobile) {
        scrollTo("#wizards", "nearest");
      } else {
        scrollTo("#wizards", "center");
      }
    }
  }, [videoParam, swiper]);

  const handleClickShowVideo = () => {
    setHideVideo(false);
  };

  const pauseVideoOnClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (window.innerWidth > 1024) {
      e.preventDefault();
      if (player) {
        setHideVideo(true);
        setIsVideoPlaying(false);
        // @ts-ignore
        video?.current.setState({ playing: false });
      }
    }
  };

  return (
    <Styled.People id={"wizards"} isVideoPlaying={isVideoPlaying}>
      <Styled.TitleWrapper>
        <Styled.Title>
          Let us introduce <span>ourselves!</span>
        </Styled.Title>
        <Styled.TitleMask isVideoPlaying={isVideoPlaying} />
      </Styled.TitleWrapper>
      <Styled.Video
        ref={videoWrapper}
        hideVideo={hideVideo}
        onClick={(e) => pauseVideoOnClick(e)}
      >
        {!hideVideoArrow && (
          <Styled.WrapperButtons className={"video-nav"}>
            <Styled.ButtonPrev onClick={() => swiper.slidePrev()}>
              <ArrowLeft />
            </Styled.ButtonPrev>
            <Styled.ButtonNext onClick={() => swiper.slideNext()}>
              <ArrowRight />
            </Styled.ButtonNext>
          </Styled.WrapperButtons>
        )}
        <ReactPlayer
          ref={video}
          onReady={(player) => setPlayer(player)}
          url={currentVideo}
          playing={!hideVideo}
          width={"100%"}
          height={"100%"}
          controls
          playIcon={
            <Styled.PlayerButtonWrapper>
              <PlayButton size={"large"} />
              <p>Watch</p>
            </Styled.PlayerButtonWrapper>
          }
          light={
            <Styled.LightWrapper>
              <Styled.LightImg
                src={currentVideoThumbnail}
                alt={peopleData[currentSlideNumber].name}
              />
              <Styled.LightText
                hide={currentSlideNumber === 0}
                // style={{ display: currentSlideNumber !== 0 ? "flex" : "none" }}
              >
                <Styled.LightName>
                  {peopleData[currentSlideNumber].name}
                </Styled.LightName>
                <Styled.LightPosition>
                  {peopleData[currentSlideNumber].position}{" "}
                  <span>({peopleData[currentSlideNumber].team})</span>
                </Styled.LightPosition>
                <Line color={peopleData[currentSlideNumber].color} />
              </Styled.LightText>
            </Styled.LightWrapper>
          }
          onPlay={() => {
            setHideVideoArrow(true);
            setIsVideoPlaying(true);
          }}
          onEnded={() => {
            setHideVideo(true);
            setIsVideoPlaying(false);
          }}
          onPause={() => {
            if (window.innerWidth <= 1024) {
              setHideVideo(true);
              setIsVideoPlaying(false);
            }
          }}
        />
      </Styled.Video>
      <Styled.Container>
        {currentSlideNumber === 0 && (
          <Styled.ImageMask
            className="image"
            src={currentSlideImg}
            alt={"We are #TECHWIZARDS!"}
            placeholder="blurred"
          />
        )}
        <Styled.WrapperLeft>
          {currentSlideNumber !== 0 && (
            <img
              className="image"
              src={currentSlideImg}
              alt={"We are #TECHWIZARDS!"}
              placeholder="blurred"
            />
          )}
          <Styled.PlayVideoButton
            onClick={() => {
              handleClickShowVideo();
            }}
          >
            Watch video <PlayButton size={"small"} />
          </Styled.PlayVideoButton>
        </Styled.WrapperLeft>
        <Styled.WrapperRight>
          <Swiper
            onSwiper={(swiper) => setSwiper(swiper)}
            style={{ width: "100%" }}
            modules={[Navigation]}
            navigation={{ prevEl, nextEl }}
            spaceBetween={10}
            slidesPerView={1}
            onSlideChange={(event) => {
              setCurrentSlideImg(peopleImgs[event.activeIndex]);
              setCurrentVideo(peopleVideos[event.activeIndex]);
              setCurrentVideoThumbnail(
                peopleVideoThumbnails[event.activeIndex]
              );
              setCurrentSlideNumber(event.activeIndex);
            }}
          >
            {peopleData.map((person, index) => {
              return (
                <SwiperSlide key={index}>
                  <Styled.PersonOld hide={currentSlideNumber === 0}>
                    <Styled.Name>{person.name}</Styled.Name>
                    <Styled.Position>
                      {person.position} <span>({person.team})</span>
                    </Styled.Position>
                    <Line color={person.color} />
                    <Styled.TextWrapper>
                      <QuotationMark color={person.color} />
                      {person.description.map((text, index) => {
                        return <Styled.Text key={index}>{text}</Styled.Text>;
                      })}
                    </Styled.TextWrapper>
                    <ButtonLink
                      text={"Join our team!"}
                      link={"#form"}
                      color={person.color}
                    />
                  </Styled.PersonOld>
                  <Styled.Persons hide={currentSlideNumber !== 0}>
                    <ButtonLink
                      text={"Join our team!"}
                      link={"#form"}
                      color={person.color}
                    />
                  </Styled.Persons>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Styled.WrapperButtons>
            <Styled.ButtonPrev ref={(node) => setPrevEl(node)}>
              <ArrowLeft />
            </Styled.ButtonPrev>
            <Styled.ButtonNext ref={(node) => setNextEl(node)}>
              <ArrowRight />
            </Styled.ButtonNext>
          </Styled.WrapperButtons>
        </Styled.WrapperRight>
      </Styled.Container>
    </Styled.People>
  );
};

export default PeopleSlider;
