import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import * as Styled from "./Form.styled";
import Input from "../../../components/Form/Input/Input";
import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Form/Checkbox/Checkbox";
import axios from "axios";
import SuccessBox from "./SuccessBox";
import Recaptcha from "../../../components/Form/Recaptcha/Recaptcha";
import Select from "../../../components/Form/Select/Select";
import ButtonLink from "../../../components/Button/ButtonLink";
import FileInput from "../../../components/Form/FileInput/FileInput";
import { PositionsList } from "./PositionsList";
import { MainInfoCheckboxes } from "./Form.styled";

type FormData = {
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  phone?: string;
  file?: File;
  uploadedFile?: string;
  regulationsAccepted: boolean;
  marketingAccepted?: boolean;
  obligatoryConsents?: boolean;
};

const Form = () => {
  const [reHash, setReHash] = useState<undefined | string | number>();
  const formRef = useRef<HTMLFormElement>(null);
  const methods = useForm<FormData>();
  const [working, setWorking] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "Server error occurred. Please try again later."
  );

  const firstConsentValue = useWatch<any>({
    control: methods.control,
    name: "additionalFields.firstConsent",
  });
  const secondConsentValue = useWatch<any>({
    control: methods.control,
    name: "additionalFields.secondConsent",
  });
  const [checkboxesError, setCheckboxesError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }
    if (firstConsentValue || secondConsentValue) {
      methods.clearErrors("obligatoryConsents");
      setCheckboxesError(false);
    } else {
      methods.setError("obligatoryConsents", {
        type: "obligatory consent not checked",
      });
      setCheckboxesError(true);
      setWorking(false);
      setIsSubmitted(false);
    }
  }, [isSubmitted, firstConsentValue, secondConsentValue]);

  const pushGtagEvent = React.useCallback(() => {
    if (!window) {
      return;
    }
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "submit",
      event_category: "submit",
      event_label: "submit",
      value: "submit",
    });
  }, []);

  const sendFormData = (data: FormData, uuid: string) => {
    // @ts-ignore
    methods.setValue("uploadedFile", uuid);
    axios
      .post(`${process.env.GATSBY_FORM_API}/contacts`, methods.getValues())
      .then((response) => {
        setWorking(false);
        setSuccess(true);
        setValidationError(false);
        pushGtagEvent();
      })
      .catch(() => {
        setError(true);
        setWorking(false);
      });
  };

  const submitData = React.useCallback((data: FormData) => {
    setValidationError(false);
    const fileForm = new FormData();
    // @ts-ignore
    const fileFormInput = document.querySelector("#file").files[0];
    // @ts-ignore
    fileForm.append("file", fileFormInput);

    axios
      .post(`${process.env.GATSBY_FORM_API}/files`, fileForm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        sendFormData(data, response.data.uuid);
      })
      .catch((err) => {
        const message = err.response.data["hydra:description"];
        if (
          message.includes("file does not exist or is not readable") ||
          message.includes("Uploaded file must be an")
        ) {
          setErrorMessage(
            "Uploaded file has wrong extension. You can only add files with the following extensions: pdf, jpg, jpeg and png."
          );
        } else {
          setErrorMessage("Server error occurred. Please try again later.");
        }
        setError(true);
        setWorking(false);
      });
  }, []);

  const onButtonClick = React.useCallback(() => {
    setIsSubmitted(true);
    setWorking(true);
    setSuccess(false);
    setError(false);
    setReHash(Math.random());
  }, []);

  const preSubmitData = React.useCallback(() => {
    methods.handleSubmit(submitData, (d) => {
      setValidationError(true);
      setWorking(false);
    })();
  }, [formRef]);

  return (
    <FormProvider {...methods}>
      {!success && (
        <Styled.Form onSubmit={methods.handleSubmit(submitData)} ref={formRef}>
          <Styled.ContentWrapper>
            <Input
              required={"Field is required"}
              name={"firstName"}
              label={"First name*"}
            />
            <Input
              required={"Field is required"}
              name={"lastName"}
              label={"Last name*"}
            />
            <Select
              name={"position"}
              options={PositionsList}
              required={"Field is required"}
            />
            <Input
              required={"Field is required"}
              name={"email"}
              label={"E-mail*"}
              pattern={{
                message: "Invalid e-mail address.",
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              }}
            />
            <Input name={"phone"} label={"Phone"} />
            <FileInput name={"file"} label={"Upload a CV file*:"} />
          </Styled.ContentWrapper>
          <Styled.MainInfo>*My data can be used:</Styled.MainInfo>
          <MainInfoCheckboxes>
            <Checkbox name={"additionalFields.firstConsent"}>
              to consider me for open positions in my country of residence (I
              consent that my personal data may be processed for this purpose as
              described in more details in the{" "}
              <a
                href="/Data_Privacy_Statement_PL_ENG.pdf"
                target={"_blank"}
                aria-label="Open Privacy Statement"
              >
                Privacy Statement
              </a>
              ).
            </Checkbox>
            <Checkbox name={"additionalFields.secondConsent"}>
              to consider me only for positions to which I have applied for (I
              consent that my personal data may be processed for this purpose as
              described in more details in the{" "}
              <a
                href="/Data_Privacy_Statement_PL_ENG.pdf"
                target={"_blank"}
                aria-label="Open Privacy Statement"
              >
                Privacy Statement
              </a>
              ).
            </Checkbox>
            {checkboxesError && (
              <Styled.Error>
                You must accept at least one of the terms.
              </Styled.Error>
            )}
          </MainInfoCheckboxes>
          <Checkbox name={"marketingAccepted"} required={false}>
            I would like to have more information about joining the Bayer Group,
            events and interesting career opportunities. I consent that my
            personal data may be processed for this purpose as described in more
            details in the{" "}
            <a
              href="/Data_Privacy_Statement_PL_ENG.pdf"
              target={"_blank"}
              aria-label="Open Privacy Statement"
            >
              Privacy Statement
            </a>
            .
          </Checkbox>
          <Styled.Info>
            Please note the{" "}
            <a
              href="/Data_Privacy_Statement_PL_ENG.pdf"
              target={"_blank"}
              aria-label="Open Privacy Statement"
            >
              Privacy Statement
            </a>
            .
          </Styled.Info>
          <Styled.ButtonWrapper>
            <Button
              text={working ? "Wait..." : "Apply!"}
              disabled={working}
              onClick={onButtonClick}
              color={"lime"}
            />
            <ButtonLink
              text={"See other job offers"}
              link={
                "https://talent.bayer.com/careers?location=Warszawa%2CMazowieckie%2CPoland&pid=562949956246675&domain=bayer.com&sort_by=relevance&triggerGoButton=false&triggerGoButton=true"
              }
              color={"transparent"}
              target={"_blank"}
            />
          </Styled.ButtonWrapper>
          <Recaptcha executionHash={reHash} onSuccess={preSubmitData} />
        </Styled.Form>
      )}
      {success && <SuccessBox />}
      {error && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
      {validationError && (
        <Styled.ErrorMessage>
          Please fill out the form correctly.
        </Styled.ErrorMessage>
      )}
    </FormProvider>
  );
};

export default Form;
