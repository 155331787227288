import React, { FunctionComponent } from "react";
import * as Styled from "./Values.styled";
import Science from "./icons/Science";
import Sustainability from "./icons/Sustainability";
import Inclusion from "./icons/Inclusion";
import ButtonLink from "../../components/Button/ButtonLink";

const Values: FunctionComponent = () => {
  return (
    <Styled.Section id="values">
      <Styled.Container>
        <Styled.Title>Our values</Styled.Title>
        <Styled.Text>
          At Digital Hub everyone can think freely without entrenched
          hierarchical structures, guidelines, and boundaries – mirroring the
          work culture of a&nbsp;dynamic start-up and sharing our values with
          Bayer:
        </Styled.Text>
        <Styled.Items>
          <Styled.Item>
            <Science />
            <Styled.ItemTitle>Science for a Better Life</Styled.ItemTitle>
            <Styled.ItemDescription>
              This is our purpose. Our digital solutions strive to prevent and
              cure diseases, improve everyday health, and help feed
              a&nbsp;growing population.
            </Styled.ItemDescription>
          </Styled.Item>
          <Styled.Item>
            <Inclusion />
            <Styled.ItemTitle>Inclusion & Diversity</Styled.ItemTitle>
            <Styled.ItemDescription>
              A culture of Inclusion and Diversity drives innovative solutions.
              We embrace and encourage our employees’ unique identities and
              believe that our human capital is one of our most valuable assets.
            </Styled.ItemDescription>
          </Styled.Item>
          <Styled.Item>
            <Sustainability />
            <Styled.ItemTitle>Sustainability</Styled.ItemTitle>
            <Styled.ItemDescription>
              We help to sustainably improve farming and access to nutrition and
              healthcare – and thus people’s lives. At the same time, we are
              reducing our ecological footprint and that of the agricultural
              industry.
            </Styled.ItemDescription>
          </Styled.Item>
        </Styled.Items>
        <ButtonLink text={"Apply now!"} link={"#form"} color={"blue"} />
        {/*<Styled.ButtonWrapper>*/}
        {/*  <ButtonLink text={"Apply now!"} link={"#form"} color={"blue"} />*/}
        {/*  <ButtonLink text={"Get to know us"} link={"#form"} color={"purple"} />*/}
        {/*</Styled.ButtonWrapper>*/}
      </Styled.Container>
    </Styled.Section>
  );
};

export default Values;
