import React from "react";
import * as Styled from "./FormSection.styled";
import Form from "./components/Form";
import Line from "../../components/Lines/Line";

const FormSection = () => {
  return (
    <Styled.FormSection id="form">
      <Styled.Line>
        <Line color={"red"} />
      </Styled.Line>
      <Styled.Container>
        <Styled.Title>Join our team!</Styled.Title>
        <Styled.Subtitle>
          and become <strong>one of us.</strong>
        </Styled.Subtitle>
        <Form />
      </Styled.Container>
    </Styled.FormSection>
  );
};

export default FormSection;
