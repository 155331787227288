import React, { FunctionComponent } from "react";

const Sustainability: FunctionComponent = () => {
  return (
    <svg
      width="75"
      height="71"
      viewBox="0 0 75 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_267_154)">
        <path
          d="M30.9182 71C30.6727 71 30.4273 71 30.2 70.9817C25.0091 70.68 20.9636 69.5372 16.6818 67.1602C11.2636 64.1523 5.70908 62.2142 1.04544 61.7296C0.41817 61.6656 -0.0272841 61.0988 0.0363523 60.4771C0.0999886 59.8463 0.654534 59.3892 1.28181 59.4623C6.24544 59.9834 12.1 62.013 17.7818 65.1672C21.8 67.3979 25.4364 68.4219 30.3273 68.7053C34.5909 68.9521 42.5182 66.4562 53.2 61.4919C60.3818 58.1549 72.4 52.5689 72.7273 49.8262C72.7364 49.7165 72.7727 49.4331 72.2 48.9759C69.6545 46.9372 60.5545 50.969 53.2545 54.2145C51.6273 54.9368 50.0091 55.659 48.4273 56.3264C47.8545 56.5733 47.1818 56.299 46.9364 55.723C46.6909 55.1379 46.9636 54.4705 47.5364 54.2237C49.1 53.5563 50.7182 52.8432 52.3273 52.1209C61.4091 48.0891 69.9909 44.2767 73.6091 47.184C74.9 48.2171 75.0636 49.3416 74.9727 50.0913C74.5636 53.5197 68.2182 57.0213 54.1454 63.5581C43.5182 68.495 35.7091 70.9909 30.9091 70.9909L30.9182 71Z"
          fill="#007395"
        />
        <path
          d="M42.2909 62.6713C40.4727 62.6713 38.1182 61.821 34.9727 60.6873C34.1091 60.3765 33.2818 60.0657 32.4818 59.764C28.7 58.3469 25.4273 57.1218 20.8 56.6281C20.1727 56.5641 19.7273 55.9973 19.7909 55.3665C19.8545 54.7356 20.4182 54.2877 21.0455 54.3517C25.9545 54.8819 29.3455 56.1527 33.2727 57.6155C34.0636 57.9172 34.8818 58.2189 35.7364 58.5297C39.0727 59.7365 41.9545 60.7788 43.0909 60.2394C43.2545 60.1662 43.4545 60.0108 43.6182 59.5628C44.2 57.9081 39.5909 53.7665 32.7818 50.905C30.7636 50.0547 29.0455 48.9302 27.3909 47.8423C25.2273 46.416 23.1818 45.0721 20.8818 44.7064C17.5545 44.1853 14.4727 44.8435 11.9091 45.5567C7.44546 46.8 3.86364 47.4217 1.13636 47.4217C0.509091 47.4308 0 46.9189 0 46.288C0 45.6572 0.509091 45.1452 1.13636 45.1452H1.16364C3.64545 45.1452 7.05455 44.5418 11.3 43.3625C13.9364 42.6311 17.4455 41.8631 21.2273 42.4574C24.0091 42.8962 26.2545 44.3681 28.6273 45.9315C30.2 46.9646 31.8273 48.0342 33.6545 48.8022C39.3091 51.1792 47.2091 56.2167 45.7545 60.3308C45.4273 61.2633 44.8545 61.9307 44.0545 62.3147C43.5273 62.5707 42.9364 62.6804 42.2818 62.6804L42.2909 62.6713Z"
          fill="#007395"
        />
        <path
          d="M45.0822 52.9894C44.7367 52.9894 44.4003 52.834 44.1731 52.5323C43.7913 52.0295 43.8913 51.3163 44.4003 50.9324C44.7185 50.6947 52.2094 45.0721 61.2367 43.2619C61.8549 43.143 62.4549 43.5362 62.5731 44.1578C62.7004 44.7795 62.3003 45.3829 61.6822 45.5018C53.1549 47.2114 45.8458 52.706 45.7731 52.7609C45.564 52.9163 45.3276 52.9894 45.0913 52.9894H45.0822Z"
          fill="#007395"
        />
        <path
          d="M41.1996 49.8079C40.8723 49.8079 40.545 49.6616 40.3177 49.3873C39.9177 48.8936 39.9996 48.1805 40.4814 47.7783C40.7268 47.5771 46.4723 42.9145 53.1632 40.894C53.7632 40.7112 54.3996 41.0586 54.5814 41.662C54.7632 42.2654 54.4177 42.9053 53.8177 43.0882C47.5723 44.9715 41.9723 49.5062 41.9177 49.5519C41.7087 49.7256 41.4541 49.8079 41.1996 49.8079Z"
          fill="#007395"
        />
        <path
          d="M36.9273 47.1292C36.6091 47.1292 36.3 47.0012 36.0727 46.7361C35.6637 46.2607 35.7091 45.5384 36.1818 45.127C36.3364 44.9899 40.0455 41.7535 45.0091 39.605C45.5818 39.3582 46.2546 39.6233 46.5 40.1993C46.7455 40.7752 46.4818 41.4518 45.9091 41.6986C41.2818 43.7008 37.7091 46.8092 37.6818 46.8458C37.4637 47.0378 37.2 47.1292 36.9364 47.1292H36.9273Z"
          fill="#007395"
        />
        <path
          d="M36.5455 36.7616C35.9546 36.7616 35.4636 36.3045 35.4091 35.7102C35.4091 35.6188 35.3818 35.2805 35.3636 34.7411C35.1091 31.4956 33.3364 20.2504 20.9909 14.6644C20.4182 14.4084 20.1636 13.7319 20.4182 13.1559C20.6727 12.5799 21.3546 12.3239 21.9182 12.5799C30.0546 16.2643 33.9909 22.344 35.8909 27.3358C36.9727 20.7715 40.0818 11.7937 48.7091 5.8511C49.2273 5.49455 49.9364 5.63169 50.2909 6.1528C50.6455 6.67392 50.5091 7.38703 49.9909 7.74358C37.6546 16.2277 37.5455 32.0258 37.6364 35.0337C37.6636 35.3994 37.6636 35.6097 37.6636 35.628C37.6636 36.2405 37.1818 36.7433 36.5727 36.7708C36.5546 36.7708 36.5455 36.7708 36.5273 36.7708L36.5455 36.7616Z"
          fill="#A7C20B"
        />
        <path
          d="M44.9088 20.3235C43.1633 20.3235 41.5088 19.7201 40.0724 18.5316C37.4724 16.3923 36.5088 12.8907 37.6179 9.59945C38.5906 6.71044 40.9906 4.20542 44.3543 2.55064C47.7452 0.877583 51.9179 -0.045801 56.1452 -8.89215e-05C56.527 -8.89215e-05 56.8815 0.201044 57.0906 0.521029C57.2997 0.841013 57.3361 1.24328 57.1815 1.59983C56.5815 2.98034 56.3452 4.51626 56.0906 6.14361C56.0088 6.66473 55.927 7.19499 55.8361 7.70697C55.3997 10.0931 54.7452 12.8633 53.1633 15.3226C51.4724 17.9556 48.8452 19.7841 46.1361 20.2138C45.727 20.2778 45.3179 20.3144 44.9179 20.3144L44.9088 20.3235ZM54.527 2.32208C51.2088 2.50493 48.0088 3.29118 45.3633 4.59855C43.727 5.40308 40.827 7.22242 39.7724 10.3308C38.9724 12.6987 39.6543 15.222 41.5179 16.758C42.7906 17.8002 44.2179 18.2025 45.7815 17.9556C47.827 17.6356 49.927 16.1454 51.2543 14.0792C52.6179 11.949 53.2088 9.45317 53.5997 7.28642C53.6906 6.79273 53.7724 6.28989 53.8452 5.77792C54.027 4.63512 54.2088 3.46489 54.527 2.31294V2.32208Z"
          fill="#A7C20B"
        />
        <path
          d="M26.1636 25.617C23.5727 25.617 20.8455 24.4194 18.7636 22.3532C16.6909 20.287 15.4455 17.7271 14.5091 15.4964C14.3091 15.0118 14.1182 14.5181 13.9182 14.0244C13.3182 12.4885 12.7546 11.044 11.8727 9.82808C11.6455 9.51724 11.5909 9.11497 11.7273 8.75842C11.8636 8.40186 12.1636 8.12759 12.5364 8.04531C16.6455 7.10364 20.9273 7.07621 24.5909 7.96303C28.2364 8.84984 31.1182 10.7789 32.6909 13.3753C34.4818 16.3375 34.3 19.967 32.2182 22.6275C30.8091 24.4285 28.8909 25.4616 26.6727 25.5987C26.5 25.6079 26.3273 25.617 26.1546 25.617H26.1636ZM14.6273 9.95608C15.1818 11.0075 15.6182 12.1137 16.0364 13.1925C16.2182 13.6679 16.4091 14.1433 16.6 14.6096C17.4546 16.6392 18.5636 18.9522 20.3546 20.7258C22.0909 22.4538 24.4636 23.4503 26.5364 23.3131C28.1091 23.2126 29.4273 22.5086 30.4364 21.2104C31.9182 19.3088 32.0455 16.7032 30.7546 14.5547C29.0546 11.748 25.8364 10.6052 24.0636 10.1755C21.2 9.48067 17.9 9.40753 14.6273 9.95608Z"
          fill="#A7C20B"
        />
      </g>
      <defs>
        <clipPath id="clip0_267_154">
          <rect width="75" height="71" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Sustainability;
